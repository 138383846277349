import axios from 'axios'
import { API_BASE_URL } from 'config/api'
import { refreshToken } from 'api'

let subscribers = []
let isAlreadyFetchingAccessToken = false

export const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter(callback => callback(token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export const logout = () => {
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('token')
  window.location.href = '/'
}

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}

export const interceptorToken = () => {
  instance.interceptors.response.use(
    res => res.data || res,
    async e => {
      const originalRequest = e.response.config
      if (e.response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          localStorage.removeItem('token')
          refreshToken(localStorage.getItem('refreshToken'))
            .then(response => {
              const newToken = response.data.access
              localStorage.setItem('token', newToken)
              configureAxiosDefaults('Authorization', newToken ? `Bearer ${newToken}` : null)
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(newToken)
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              logout()
            })
        }
        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      Promise.reject(e)
      return null
    }
  )
}

export function removeAxiosTokenHeader() {
  const header = 'Authorization'
  delete instance.defaults.headers.common[header]
}

export function post(url, data) {
  return instance
    .post(url, data)
    .then(response => response)
    .catch(error => {
      console.log(error)
      if (error && error.response) {
        console.log(error.response)
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}

export function get(url, config) {
  return instance
    .get(url, config)
    .then(response => response)
    .catch(error => {
      if (error && error.response) {
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}

export function put(url, data) {
  return instance
    .put(url, data)
    .then(response => response)
    .catch(error => {
      if (error && error.response) {
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}

export function patch(url, data) {
  return instance
    .patch(url, data)
    .then(response => response)
    .catch(error => {
      if (error && error.response) {
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}

export function remove(url, data) {
  return instance
    .delete(url, { data })
    .then(response => response)
    .catch(error => {
      if (error && error.response) {
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}
