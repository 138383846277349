import { Select, ButtonBase } from '@material-ui/core'
import { colors } from 'config/colors'
import styled from 'styled-components'

export const ListContainer = styled.div``

export const ListHeader = styled.div`
  font-family: 'lato';
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 30px;
  color: black;
  border-bottom: 1px solid #e7e7e7;
  font-weight: 700;
  font-size: 14px;
`

export const ListRow = styled.div`
  font-family: 'Lato';
  font-size: 13px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
  &:hover {
    color: #000;
    background-color: #d7d7d7;
    cursor: default;
  }
`

export const ListRowNoStyle = styled.div`
  font-family: 'Lato';
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid orange;
  align-items: center;
`

export const PaginationNumbers = styled.span`
  font-family: 'lato';
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  font-size: 14px;
  & > span {
    display: none;
  }
  .MuiPaginationItem-page {
    font-size: 14px !important;
    color: #4c4c4c;
  }
`
export const ListLargeItem = styled.div`
  min-width: 250px;
  color: black;
`

export const ListIndex = styled.div`
  color: ${colors.gray};
  width: 25px;
`

export const ListItem = styled.div`
  // color: ${colors.gray};
  color: #2b2b2b;
  ©
`
export const ListItemBold = styled.div`
  color: black;
  width: 200px;
  font-weight: 700;
`

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
  justify-content: start;
  font-size: 13px;
  .MuiButton-text {
    padding: 0 !important;
    justify-content: start;
  }
`
export const ListSmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  align-items: center;
  font-size: 13px;
  color: black;
  justify-content: start;
  .MuiButton-text {
    padding: 0 !important;
    justify-content: start;
  }
`

export const ListSmallItemBold = styled.div`
  color: black;
  width: 100px;
  font-weight: 700;
`

export const ListSmallItem = styled.div`
  color: ${colors.gray};
  width: 100px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 150px 0;
`

export const SmallButton = styled(ButtonBase)`
  &:hover {
    background-color: ${colors.orange};
    color: white;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 10px 15px 0 15px;
  .MuiFormLabel-root {
    font-size: 15px;
    color: #a2a2a2;
    left: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    padding: 0 15px !important;
    border: 1px solid #ededed;
    border-radius: 15px;
  }
`

export const FilterInput = styled.div`
  margin: 0 5px;
`

export const FilterSelect = styled(Select)`
  width: 130px !important;
  li {
    font-size: 15px !important;
  }
`

export const ListBigNumber = styled.h1`
  font-family: 'lato';
  font-size: 50px;
  font-weight: 700;
`
export const ClubCard = styled.div`
  width: 250px;
  height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  margin: 10px;
  padding: 15px;
  color: black;
  font-family: 'lato';
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const NoStyleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-family: 'lato';
  font-size: 15px;
  margin: 15px 0;
  color: #999;
`
export const RowEnd = styled.div`
  display: flex;
  justify-content: end;
`
export const ListItemStatus = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  font-weight: 900;
  width: 50px;
  &:hover {
    background-color: ${colors.gray} !important;
  }
`

export const StateBtn = styled.button`
  background-color: transparent;
  border: 0;
  color: white;
  font-weight: 900;
  font-size: 12px;
  font-family: 'lato';
  &:hover {
    cursor: pointer !important;
  }
`
