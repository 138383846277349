import React, { Component } from 'react'
import { initTranslation } from 'assets/i18n'
import 'App.css'
import CreateBookingMeeting from 'components/organisms/bookings/CreateBookingMeeting'
import map from 'lodash/map'
import { getPublicClubs } from 'api'
import Selector from 'components/atoms/selector'
import DateFnsUtils from '@date-io/date-fns'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      office: null,
      officesOptions: [],
    }
    // interceptorToken()
    initTranslation()

    getPublicClubs().then(({ data }) => {
      const options = map(data, ({ slug, name }) => ({
        id: slug,
        text: name,
      }))
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const club = urlParams.get('club')
      let defaultOffice = data[2]
      if (club) {
        const findOffice = data.find(office => office.slug === club)
        if (findOffice) {
          defaultOffice = findOffice
        }
        console.log(findOffice)
      }
      this.setState({ officesOptions: options, office: defaultOffice })
    })
  }

  render() {
    const { office, officesOptions } = this.state
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider maxSnack={3}>
          <div style={{ marginTop: 10, marginLeft: 30, marginBottom: 10 }}>
            <Selector
              value={office?.slug}
              type='title'
              options={officesOptions}
              onChange={value => {
                const selected = officesOptions.find(item => item.id === value)
                this.setState({
                  office: { slug: selected.id },
                })
              }}
            />
          </div>
          {office && <CreateBookingMeeting office={office} />}
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    )
  }
}

export default App
