import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Schedule from 'components/molecules/schedule/schedule'
import { CircularProgress, Grid } from '@material-ui/core'
import { SpinnerContainer } from '../members/newListStyle'

function CreateBookingMeeting({ office }) {
  useEffect(() => {}, [office])
  return (
    <Grid container>
      {office !== undefined ? (
        <Grid item>
          {' '}
          <Schedule office={office} />
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

CreateBookingMeeting.propTypes = {
  office: PropTypes.object,
}

CreateBookingMeeting.defaultProps = {
  office: {},
}

export default CreateBookingMeeting
