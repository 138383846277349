import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Scheduler, { Resource, View } from 'devextreme-react/scheduler'
import 'devextreme/dist/css/dx.light.css'
import { getBookings, getResourcesForOffice, setBooking } from 'api'
import { CircularProgress, Container, Modal } from '@material-ui/core'
import { ModalCard, ModalTitle } from 'components/organisms/bookings/modalStyles'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { ModalText } from 'components/organisms/campaigns/campaignStyles'
import ModalSchedulerPublicBooking from 'components/organisms/bookings/modalSchedulerPublicBooking'

function Schedule({ ...props }) {
  const { office, team } = props
  const [rooms, setRooms] = useState([])
  const [bookings, setBookingData] = useState([])
  const [appointments, setAppointments] = useState([])
  const [confirmModal, setConfirm] = useState(false)
  const [modalBooking, setModal] = useState(false)
  const [resourceData, setResourceData] = useState()

  const startDayHour = 8
  const endDayHour = 19
  const currentDate = new Date()
  const startDate = new Date(currentDate)
  startDate.setDate(startDate.getDate() - 4)
  const endDate = new Date(currentDate)
  endDate.setDate(endDate.getDate() + 4)
  const history = useHistory()

  function randomHexColorCode() {
    const a = '#'
    const n = (Math.random() * 0xfffff * 1000000).toString(16)
    return a + n.slice(0, 6)
  }
  function getRoomResources() {
    const roomsArr = []
    getResourcesForOffice(office.slug).then(response => {
      response.data.map(res => {
        // if (res.resource_type === 'meeting_room') {
        const room = {
          id: res.slug,
          text: res.name,
          color: res.color === null ? randomHexColorCode() : res.color,
        }
        roomsArr.push(room)
        // }
        return ''
      })
      setRooms(roomsArr)
    })
  }

  function formatDate(data, type) {
    const date = data.toString()
    const dateSplit = date.split(' ')
    const tab = '-'
    const t = 'T'
    const startTime = '00:00:00'
    const endTime = '23:59:59'
    const realTime = dateSplit[4]
    let time = ''
    switch (type) {
      case 'start':
        time = startTime
        break
      case 'end':
        time = endTime
        break
      default:
        time = realTime
    }
    if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
    if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
    if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
    if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
    if (dateSplit[1] === 'May') dateSplit[1] = '05'
    if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
    if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
    if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
    if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
    if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
    if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
    if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
    const formatData = dateSplit[3] + tab + dateSplit[1] + tab + dateSplit[2] + t + time
    return formatData
  }

  function createAppointments(bookingsArr) {
    const bookedsArr = []
    bookingsArr.map(b => {
      const booking = {
        roomId: b?.resource?.slug,
        text: b?.member?.team?.name,
        startDate: new Date(b.planned_start),
        endDate: new Date(b.planned_end),
        bookingCode: b?.code,
        teamData: b?.member?.team,
        memberData: b?.member,
      }
      return bookedsArr.push(booking)
    })
    setAppointments(bookedsArr)
  }

  function getBookingsInfo() {
    const query = {
      pageSize: '1000',
      currentPage: 1,
      filter: {
        name: '',
        order: '',
        layoutOffice: office.slug,
        booking_type: 'meeting_room',
        start: formatDate(startDate, 'start'),
        end: formatDate(endDate, 'end'),
      },
    }
    getBookings(query).then(response => {
      setBookingData(response.data)
    })
  }

  function checkFields() {
    getBookingsInfo()
    getRoomResources()
  }

  function createNewBooking(data) {
    const newBooking = [
      {
        start: { dateTime: formatDate(data.startDate) },
        end: { dateTime: formatDate(data.endDate) },
        office,
        payNow: false,
        team,
        booking_type: 'meeting_room',
        resource: data.roomId,
        capacity: 1,
        price: 0,
        origin: 'regular',
        comments: data.description,
      },
    ]
    setBooking(newBooking)
      .then(setConfirm(true))
      .then(
        setTimeout(() => {
          history.push('../bookings')
        }, 3000)
      )
  }

  useEffect(() => {}, [confirmModal])
  useEffect(() => {
    checkFields()
    if (rooms === 'cacafuti') createNewBooking()
  }, [office])

  useEffect(() => {
    if (bookings.length > 0 && rooms.length > 0) createAppointments(bookings)
  }, [bookings, rooms])

  useEffect(() => {}, [appointments])

  function editedFormScheduler(data) {
    const uploadResourceData = {
      resource: data.appointmentData.roomId,
      start: data.appointmentData.startDate,
      end: data.appointmentData.endDate,
      uploadContent: {
        team: data.appointmentData.teamData,
        member: data.appointmentData.memberData,
        code: data.appointmentData.bookingCode,
      },
    }
    setResourceData(uploadResourceData)
    if (data.appointmentData.startDate > new Date() || data.appointmentData.bookingCode !== undefined) setModal(true)
  }

  function closeModal() {
    setModal(false)
  }

  const groups = ['roomId']
  return (
    <Container>
      <Scheduler
        dataSource={appointments}
        onAppointmentAdding={appoAdd => createNewBooking(appoAdd.appointmentData)}
        onAppointmentUpdated={appoUp => console.log(appoUp.appointmentData)}
        // onAppointmentClick={e => {
        //   e.cancel = true
        //   editedFormScheduler(e)
        // }}
        onAppointmentFormOpening={e => {
          e.cancel = true
          editedFormScheduler(e)
        }}
        groups={groups}
        defaultCurrentView='Day'
        defaultCurrentDate={currentDate}
        startDayHour={startDayHour}
        endDayHour={endDayHour}
        showAllDayPanel={false}
      >
        <View name='Day' type='day' cellDuration={30} intervalCount={1} />
        <View name='Week' type='workWeek' cellDuration={30} intervalCount={1} />
        <Resource fieldExpr='roomId' allowMultiple={false} dataSource={rooms} label='Room' />
      </Scheduler>
      <Modal open={modalBooking} onClose={closeModal}>
        <ModalSchedulerPublicBooking
          closeModal={closeModal}
          rooms={rooms}
          office={office}
          resourceData={resourceData}
        />
      </Modal>
      <Modal open={confirmModal}>
        <ModalCard>
          <ModalTitle>Booking created</ModalTitle>
          <ModalText>You will redirect to Booking List</ModalText>
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        </ModalCard>
      </Modal>
    </Container>
  )
}

Schedule.propTypes = {
  office: PropTypes.object,
  team: PropTypes.string,
}

Schedule.defaultProps = {
  office: {},
  team: '',
}

export default Schedule
