import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import Text from 'components/atoms/text'

export const Container = styled.div`
  cursor: pointer;
  display: inline-block;
`
export const InputSelector = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: ${({ type }) => (type === 'title' ? '18px' : '15px')};
    fill: ${({ color }) => color};
  }
`
export const SelectorMenuItem = styled(MenuItem)``

export const SelectedText = styled(Text)`
  font-size: ${({ type }) => (type === 'title' ? '24px' : '14px')};
  color: ${({ color }) => color};
`
