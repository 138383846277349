import React, { useEffect, useState } from 'react'
import { setClubBooking, getClubMeetingRoomAvailability } from 'api'
import PropTypes from 'prop-types'
import { Grid, FormControl, TextField, Button, Modal } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import formatDates from 'components/atoms/formatDates/formatDates'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard, ModalTitle } from './modalStyles'
import { validateEmail } from '../../../utils/utils'

function ModalSchedulerPublicBooking({ closeModal, rooms, office, resourceData }) {
  const stripe = window.Stripe(
    'pk_live_51HyyLXIqEvEQsg487VwiqYnscN5RtChA0j5bd6nbjkvSQtLPzkZhknpXldLIOlTKJ07Q8EVI0VmdgKVIf5pLb9lY008DwcvamO'
  )
  const [theBooking, setBookingData] = useState({
    comments: '',
    resource: resourceData.resource,
    price: resourceData.price,
    initialPrice: resourceData.price,
    use_credits: 'NO',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  })
  const [errors, setErrors] = useState({
    start: null,
    end: null,
    bookedStart: null,
    bookedEnd: null,
    badTime: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
  })
  const [fbModal, setfbModal] = useState(false)

  function getMeetingRoomAvailabilityData(value, type) {
    const query = {
      resource: resourceData.resource,
      start:
        theBooking.start === undefined
          ? formatDates(resourceData.start, 'time')
          : formatDates(theBooking.start, 'time'),
      end: theBooking.end === undefined ? formatDates(resourceData.end, 'time') : formatDates(theBooking.end, 'time'),
    }
    if (type === 'start') query.start = formatDates(value, 'time')
    if (type === 'end') query.end = formatDates(value, 'time')
    if (query.start > query.end) {
      setErrors({
        ...errors,
        badTime: 'End time cant be before start time',
      })
    }
    if (new Date(query.start) < new Date(query.end)) {
      setErrors({
        ...errors,
        badTime: null,
      })
      getClubMeetingRoomAvailability(query).then(response => {
        const r = response.data
        if (r.status === 'busy') {
          if (r.bookings.length !== 1 || theBooking?.start < r.planned_end || theBooking?.end > r.planned_end) {
            setErrors({
              ...errors,
              bookedStart: `Meeting room booked from: ${r.bookings[0].planned_start.slice(11, 16)}`,
              bookedEnd: `Meeting room booked to: ${r.bookings[0].planned_end.slice(11, 16)}`,
            })
          }
        }
        if (r.status === 'free') setErrors({ ...errors, bookedStart: null, bookedEnd: null })
        setBookingData({
          ...theBooking,
          price: r.price.toFixed(2),
          initialPrice: r.price.toFixed(2),
          credits: r.credits.toFixed(2),
          start: type === 'start' ? value : theBooking.start,
          end: type === 'end' ? value : theBooking.end,
          resource: resourceData.resource,
          available_credits: r.available_credits,
        })
      })
    }
  }

  useEffect(() => {
    getMeetingRoomAvailabilityData()
  }, [resourceData])

  useEffect(() => {
    if (theBooking.start === undefined) {
      setBookingData({
        ...theBooking,
        start: resourceData.start,
        end: resourceData.end,
      })
    }
  }, [theBooking])

  useEffect(() => {}, [rooms, office])

  // eslint-disable-next-line no-unused-vars
  function createBooking() {
    const bookingsArr = []
    const booking = {
      start: { dateTime: formatDates(theBooking.start, 'time') },
      end: { dateTime: formatDates(theBooking.end, 'time') },
      booking_type: 'meeting_room',
      resource: resourceData.resource,
      price: theBooking.price ? theBooking.price.toString() : '0',
      email: theBooking.email,
      first_name: theBooking.first_name,
      last_name: theBooking.last_name,
      phone: theBooking.phone,
      intent: '',
    }
    bookingsArr.push(booking)
    setClubBooking(bookingsArr)
      .then(setfbModal(true))
      .then(
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      )
  }

  const createIntent = () => {
    const orderData = {
      locale: 'en-gb',
      price_code: 'default',
      quantity: 1,
      amount: theBooking.price,
      name: 'Meeting room booking',
      description: 'Lorem ipsum ....',
      club: office.short_code,
      resource: resourceData.resource,
      email: theBooking.email,
      first_name: theBooking.first_name,
      last_name: theBooking.last_name,
      phone: theBooking.phone,
      start: theBooking.start,
      end: theBooking.end,
      comments: theBooking.comments,
    }
    fetch('https://app.workersleague.com/create-mr-checkout-session/', {
      //fetch('http://127.0.0.1:8000/create-mr-checkout-session/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    }).then(async res => {
      const data = await res.json()
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then(result => console.log(result))
    })
  }

  function validate(data) {
    let canCreate = false
    const textErrors = {
      start: 'Start time is required',
      end: 'End time is required',
      room: 'Room is required',
      first_name: 'First Name is required',
      last_name: 'Last Name is required',
      phone: 'Phone is required',
      email: 'Email is required',
      emailCheck: 'A valid email is required',
    }

    const startRes = data.start === null || data.start === undefined || data.start === '' ? textErrors.start : null
    const endRes = data.end === null || data.end === undefined || data.end === '' ? textErrors.end : null
    const firstNameRes =
      data.first_name === null || data.first_name === undefined || data.first_name === '' ? textErrors.first_name : null
    const lastNameRes =
      data.last_name === null || data.last_name === undefined || data.last_name === '' ? textErrors.last_name : null
    let emailRes = data.email === null || data.email === undefined || data.email === '' ? textErrors.email : null

    if (emailRes === null) {
      emailRes = validateEmail(data.email) ? null : textErrors.emailCheck
    }

    const phoneRes = data.phone === null || data.phone === undefined || data.phone === '' ? textErrors.phone : null

    setErrors({
      start: startRes,
      end: endRes,
      bookedStart: errors.bookedStart,
      bookedEnd: errors.bookedEnd,
      badTime: errors.badTime,
      first_name: firstNameRes,
      last_name: lastNameRes,
      email: emailRes,
      phone: phoneRes,
    })

    if (
      startRes === null &&
      endRes === null &&
      errors.bookedStart === null &&
      errors.bookedEnd === null &&
      errors.badTime === null &&
      firstNameRes === null &&
      lastNameRes === null &&
      emailRes === null &&
      phoneRes === null
    ) canCreate = true

    if (canCreate === true) {
      createIntent()

      //createBooking()
    }
  }

  return (
    <ModalCard>
      <Modal open={fbModal}>
        <FeedbackModal type='booking' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '15px' }}>
            <Grid item>
              <ModalTitle>New Meeting Room booking</ModalTitle>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={2}>
                <Grid item>
                  <Button variant='outlined' size='small' onClick={() => closeModal()}>
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label='Comments'
              variant='outlined'
              onChange={e => setBookingData({ ...theBooking, comments: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                label='First Name'
                variant='outlined'
                error={errors.first_name}
                helperText={errors.first_name}
                onChange={e => setBookingData({ ...theBooking, first_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                label='Last Name'
                variant='outlined'
                error={errors.last_name}
                helperText={errors.last_name}
                onChange={e => setBookingData({ ...theBooking, last_name: e.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                label='Email'
                error={errors.email}
                helperText={errors.email}
                variant='outlined'
                onChange={e => setBookingData({ ...theBooking, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                label='Phone'
                error={errors.phone}
                helperText={errors.phone}
                variant='outlined'
                onChange={e => setBookingData({ ...theBooking, phone: e.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='Start'
                  inputVariant='outlined'
                  minutesStep={5}
                  onChange={value => {
                    setBookingData({ ...theBooking, start: value })
                    getMeetingRoomAvailabilityData(value, 'start')
                  }}
                  value={theBooking?.start === undefined ? resourceData.start : theBooking.start}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  error={errors.start !== null || errors.bookedStart !== null}
                />
                {errors.start !== null ? <SmallError>{errors.start}</SmallError> : ''}
                {errors.bookedStart !== null ? <SmallError>{errors.bookedStart}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  inputVariant='outlined'
                  label='End'
                  minutesStep={5}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='outlined'
                      error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                    />
                  )}
                  onChange={value => {
                    setBookingData({ ...theBooking, end: value })
                    getMeetingRoomAvailabilityData(value, 'end')
                  }}
                  value={theBooking?.end === undefined ? resourceData.end : theBooking.end}
                  error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                />
                {errors.end !== null ? <SmallError>{errors.end}</SmallError> : ''}
                {errors.bookedEnd !== null ? <SmallError>{errors.bookedEnd}</SmallError> : ''}
                {errors.badTime !== null ? <SmallError>{errors.badTime}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Price'
                  value={theBooking.price === undefined ? 0 : theBooking.price}
                  type='number'
                  inputProps={{ readOnly: true }}
                  variant='outlined'
                  onChange={e =>
                    setBookingData({
                      ...theBooking,
                      price: e.target.value >= 0 ? e.target.value : 0,
                    })
                  }
                >
                  Price
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item justify='center'>
          <Grid container direction='row' justify='flex-end' style={{ marginTop: '15px' }}>
            <Grid item>
              <Button variant='contained' color='secondary' onClick={() => validate(theBooking)}>
                Create Booking
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalSchedulerPublicBooking.propTypes = {
  closeModal: PropTypes.func,
  rooms: PropTypes.array,
  office: PropTypes.object,
  resourceData: PropTypes.object,
}

ModalSchedulerPublicBooking.defaultProps = {
  closeModal: () => {},
  rooms: [],
  office: {},
  resourceData: {},
}

export default ModalSchedulerPublicBooking
