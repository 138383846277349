export const formatDate = dateString => {
  if (!dateString) return ''
  const theDate = dateString.toString().split(' ')
  if (theDate[1] === 'Jan') theDate[1] = '01'
  if (theDate[1] === 'Feb') theDate[1] = '02'
  if (theDate[1] === 'Mar') theDate[1] = '03'
  if (theDate[1] === 'Apr') theDate[1] = '04'
  if (theDate[1] === 'May') theDate[1] = '05'
  if (theDate[1] === 'Jun') theDate[1] = '06'
  if (theDate[1] === 'Jul') theDate[1] = '07'
  if (theDate[1] === 'Aug') theDate[1] = '08'
  if (theDate[1] === 'Sep') theDate[1] = '09'
  if (theDate[1] === 'Oct') theDate[1] = '10'
  if (theDate[1] === 'Nov') theDate[1] = '11'
  if (theDate[1] === 'Dec') theDate[1] = '12'
  return `${theDate[3]}/${theDate[1]}/${theDate[2]}`
}

export const calculateGrossPrice = (price, vat) => Math.round((price / (1 + vat / 100)) * 100) / 100
export const calculateTotalLine = (price, units) => Math.round(price * units * 100) / 100
export const calculateGrossTotalLine = (price, vat, units) =>
  Math.round(calculateGrossPrice(price, vat) * units * 100) / 100

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isEmptyObject = obj => Object.keys(obj).length === 0

export const findObjectById = (id, list) => {
  const items = list.filter(m => m.id === id)
  return items.length ? items[0] : null
}

export const findObjectByPK = (id, list) => {
  const items = list.filter(m => m.pk === id)
  return items.length ? items[0] : null
}
